function onFetchInit(data) {
  return {
    form: {
      name: data.configLabels[0].name,
      a2: data.configLabels[0].labelTypes[0].farmLabelDataVOS.map(e => ({
        name: e.labelName,
        value: e.id,
        select: e.status == 0 ? 1 : 0
      }))
    },
    typeData: {
      a2: data.configLabels[0].labelTypes[0].farmLabelDataVOS.map(e => ({
        name: e.labelName,
        value: e.id,
        select: e.status == 0 ? 1 : 0
      }))
    }
  }
}

function onSubmit({ detail, form }) {
  const obj = { ...detail }
  obj['configLabels'][0]['name'] = form.name
  obj['configLabels'][0]['labelTypes'][0]['farmLabelDataVOS'].forEach((e, i) => {
    e.status = form['a2'][i].select == 0 ? 1 : 0
  })
  return obj
}

export default {
  type: 'form',
  url: '/config/farmConfigLabel/list',
  edit: '/config/farmConfigLabel/update',
  params: {
    type: '1'
  },
  onFetchInit,
  onSubmit,
  data: [
    {
      name: '标签名',
      type: 'input',
      key: 'name'
    },
    {
      name: '服务-筛选',
      type: 'labelGroup',
      key: 'a2'
    }
  ]
}
